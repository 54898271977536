<template>
  <AppFooterBase
    v-slot="{ forBizLinks, resourceLinks, companyLinks, contactPhone }"
    class="bg-[rgba(230,195,68,0.223)] pb-6 pt-8 sm:pb-3"
  >
    <v-container>
      <RouterLink to="/">
        <Logo size="40" color="black" lucid />
      </RouterLink>
      <div
        class="mt-4 col-span-8 grid grid-cols-8 md:grid-cols-15 items-start gap-x-2 gap-y-6 md:gap-x-6 sm:col-span-16 sm:grid-cols-16 sm:gap-3"
      >
        <section
          class="col-span-8 flex flex-col space-y-1 text-sm md:col-span-6 lt-md:mb-8"
        >
          <h2 class="text-8 line-height-none font-semibold">
            Join Orie Community and receive a <mark>10% discount</mark>
            <br />
            welcome voucher
          </h2>
          <p class="my-4! text-4.5 font-light">
            Bring your business to the creative marketplace where millions of
            shoppers are looking for products like yours.
          </p>
          <div class="mb-4!">
            <RouteLink
              class="hover:text-orange"
              :to="{ name: 'sell' }"
              text="Learn more"
              dotted
            />
          </div>
          <VArrowBtn
            :href="dashboardUrl"
            color="black"
            class="fit-content"
            target="_blank"
          >
            Seller Dashboard
          </VArrowBtn>
        </section>
        <section
          class="col-span-4 flex flex-col space-y-1 text-sm md:col-span-3"
        >
          <h2 class="list-title">For Business</h2>
          <ul class="space-y-1 list-none">
            <li v-for="link in forBizLinks" :key="link.text">
              <RouteLink v-bind="link" class="hover:text-orange" />
            </li>
          </ul>
        </section>
        <section
          class="col-span-4 flex flex-col space-y-1 text-sm md:col-span-3"
        >
          <h2 class="list-title">Resources</h2>
          <ul class="space-y-1 list-none">
            <li v-for="link in resourceLinks" :key="link.text">
              <RouteLink v-bind="link" class="hover:text-orange" />
            </li>
          </ul>
        </section>
        <section
          class="col-span-full flex flex-col space-y-1 text-sm md:col-span-3"
        >
          <h2 class="list-title">Orie</h2>
          <ul class="space-y-1 list-none">
            <li v-if="contactPhone">
              <RouteLink class="hover:text-orange" :phone="contactPhone" />
            </li>
            <li v-for="link in companyLinks" :key="link.text">
              <RouteLink v-bind="link" class="hover:text-orange" />
            </li>
          </ul>
        </section>
      </div>
    </v-container>
  </AppFooterBase>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import AppFooterBase from "./AppFooterBase.vue";

export default defineComponent({
  components: { AppFooterBase },
  setup() {
    const config = useRuntimeConfig();

    return {
      dashboardUrl: config.public.links.dashboard,
    };
  },
});
</script>
